import {Controller} from "@hotwired/stimulus"
import LazyLoad from "vanilla-lazyload";

// Connects to data-controller="admin--member-talk"
export default class extends Controller {
    static targets = ["messagesScroller", "messages", "textForm", "imageForm", "reloadForm", "imageInput", "markAsReadForm", "logsScroller", "loadingIndicator"]
    messagesResizeObserver = null
    messagesMutationObserver = null
    imageInputChangedAt = null
    onVisibilitychangeEventListener = null
    onFocusWindowEventListener = null
    onTurboFrameMissingEventListener = null

    connect() {
        this.messagesResizeObserver = new ResizeObserver(body => {
            this.messagesScrollerTarget.scrollTop = this.messagesScrollerTarget.scrollHeight;
        });

        this.messagesResizeObserver.observe(this.messagesTarget);

        this.onVisibilitychangeEventListener = () => {
            if (document.visibilityState === "visible") {
                this._reload()
            }
        }

        this.onFocusWindowEventListener = () => {
            this._reload()
        }

        if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) {
            document.addEventListener("visibilitychange", this.onVisibilitychangeEventListener);
        } else {
            window.addEventListener("focus", this.onFocusWindowEventListener);
        }

        this.onTurboFrameMissingEventListener = (event) => {
            event.preventDefault()
            Turbo.visit(event.detail.response)
        }

        window.addEventListener("turbo:frame-missing", this.onTurboFrameMissingEventListener)

        this.messagesMutationObserver = new MutationObserver(mutations => {
            if (mutations.map((mutation) => {
                return Array.from(mutation.addedNodes).filter((addedNode) => {
                    return addedNode.dataset && addedNode.dataset.memberTalkMessageCreatedByType === 'Member'
                }).length > 0
            }).filter(value => value === true).length > 0) {
                const latestMemberTalkMessage = Array.from(document.querySelectorAll("*[data-member-talk-message-id]")).slice(-1)[0]

                if (latestMemberTalkMessage) {
                    this.markAsReadFormTarget.latest_member_talk_message_id.value = latestMemberTalkMessage.dataset.memberTalkMessageId
                    this.markAsReadFormTarget.requestSubmit()
                }
            }
        });

        this.messagesMutationObserver.observe(this.messagesTarget, {childList: true})
        new LazyLoad({}).update()

        this.messagesTarget.addEventListener("turbo:frame-render", (event) => {
            new LazyLoad({}).update()
        })
    }

    disconnect() {
        this.messagesResizeObserver.disconnect()
        this.messagesMutationObserver.disconnect()
        document.removeEventListener("visibilitychange", this.onVisibilitychangeEventListener)
        window.removeEventListener("focus", this.onFocusWindowEventListener);
        window.removeEventListener("turbo:frame-missing", this.onTurboFrameMissingEventListener);
    }

    clear(event) {
        event.target.reset()
    }

    onChangeImageInput(event) {
        this.imageInputChangedAt = Date.now()
        this.imageFormTarget.requestSubmit()
        this.imageFormTarget.reset()
    }

    onLoadMessages() {
        this.loadingIndicatorTarget.style.display = "none"
    }

    _reload() {
        if (this.imageInputChangedAt) {
            setTimeout(() => {
                this.reloadFormTarget.requestSubmit()
                this.imageInputChangedAt = null
            }, (this.imageInputChangedAt + 3000) - Date.now())
        } else {
            this.reloadFormTarget.requestSubmit()
        }
    }
}
